import React from 'react';
import logo from './logo.svg';
import './App.css';
import * as Comlink from 'comlink';
import {WorkerType} from "./worker";

interface FileDropBoxProps {
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void,
  value: string
}

interface FileDropBoxState {
  active: boolean,
  fileName?: string
}

class FileDropBox extends React.Component<FileDropBoxProps, FileDropBoxState> {
  constructor(props: FileDropBoxProps) {
    super(props);
    this.state = {
      active: false
    }
  }

  handleDragLeave(event: React.DragEvent<HTMLDivElement>) {
    this.setState(state => {
      return {
        active: false,
        fileName: state.fileName
      }
    });
  }

  handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();

    this.setState(state => {
      return {
        active: true,
        fileName: state.fileName
      }
    });
  }

  boxClasses() {
    const baseClasses = "card file-drop-box";
    return this.state.active ? baseClasses + " bg-primary" : baseClasses + " bg-light";
  }

  render() {
    return (
        <div className="col col-sm-6">
          <div className={this.boxClasses()}
               onDrop={event => this.props.onDrop(event)}
               onDragOver={event => this.handleDragOver(event)}
               onDragLeave={event => this.handleDragLeave(event)}
          >
            <div className="card-body text-center">
              <p className="card-text">{this.state.fileName ? this.state.fileName : this.props.value}</p>
            </div>
          </div>
        </div>
    );
  }
}

interface PdfUploadState {
  frontPagesFile?: File,
  backPagesFile?: File,
  running: boolean,
  progress: number
}

class PdfUpload extends React.Component<any, PdfUploadState> {
  constructor(props: any) {
    super(props);

    this.state = {
      running: false,
      progress: 0
    }
  }

  handleDropFrontPagesFile(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();

    // @ts-ignore
    const fileName = event.dataTransfer.files[0];

    this.setState(state => {
      return {
        frontPagesFile: fileName,
        backPagesFile: state.backPagesFile
      }
    });
  }

  handleDropBackPagesFile(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();

    // @ts-ignore
    const fileName = event.dataTransfer.files[0];

    this.setState(state => {
      return {
        frontPagesFile: state.frontPagesFile,
        backPagesFile: fileName
      }
    });
  }

  buttonClasses() {
    return this.state.frontPagesFile && this.state.backPagesFile ? "btn btn-primary" : "btn btn-light";
  }

  mergePdfs() {
    const frontPagesFile = this.state.frontPagesFile;
    const backPagesFile = this.state.backPagesFile;

    if (frontPagesFile && backPagesFile) {

      const downloadLink = document.getElementById("downloadLink");

      const worker = new Worker(new URL("worker", import.meta.url));
      this.setState(state => {
        return {
          running: true,
          progress: 0
        }
      });
      const obj = Comlink.wrap<WorkerType>(worker);

      obj.mergePdfs(frontPagesFile, backPagesFile, Comlink.proxy((progress) => this.setProgress(progress))).then((newPdfBytes) => {
        this.setState(state => {
          return {
            running: false
          }
        });

        var blob = new Blob([newPdfBytes], {type: "application/pdf"});

        // @ts-ignore
        downloadLink.download = "zusammengefügt.pdf";
        // @ts-ignore
        downloadLink.href = window.URL.createObjectURL(blob);
        // @ts-ignore
        downloadLink.click();
        // @ts-ignore
        downloadLink.href = ""
      }).finally(() => {
        worker.terminate()
      });
    }
  }

  setProgress(progress: number) {
    this.setState(state => {
      return {
        progress: Math.ceil(progress)
      }
    });
  }

  progressBarClassNames() {
    return this.state.running ? "progress" : "invisible";
  }

  render() {
    return (
          <div className="container">
            <main>
              <div className="row mb-2">
                <FileDropBox value={this.state.frontPagesFile ? this.state.frontPagesFile.name : "Vorderseiten"} onDrop={event => this.handleDropFrontPagesFile(event)} />
                <FileDropBox value={this.state.backPagesFile ? this.state.backPagesFile.name : "Rückseiten"} onDrop={event => this.handleDropBackPagesFile(event)} />
              </div>
              <button className={this.buttonClasses()} onClick={() => this.mergePdfs()}>Zusammenfügen</button>
              <div className={this.progressBarClassNames()}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={this.state.progress} aria-valuemin={0}
                     aria-valuemax={100} style={{width: this.state.progress + "%"}}>{this.state.progress}%</div>
              </div>
              <a id="downloadLink"></a>
            </main>
          </div>
    );
  }
}

function App() {
  return (
    <PdfUpload />
  );
}

export default App;
